@charset "utf-8";
/*
#overview
modBtn
*/

/*
#styleguide
xxxxx

```
コード
```
*/

.mod-btn-01 {
  background-color: $themeColor;
  color: #fff;
  font-size: (16 / $baseFont) * 100%;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  position: relative;
  span {
    position: relative;
    z-index: 2;
  }
  padding: 14px 40px;
    &::after {
    width: 100px;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 150px;
    height: 13px;
    background: url(../images/common/bg_arrow-long-white.svg) no-repeat 50% 50%;
    background-size: 100% auto;
    margin-left: 20px;
    position: relative;
    z-index: 2;
  }

  @media only screen and (min-width: 768px){
    padding: 20px 80px;
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 0;
      transition: all .5s ease-in-out;
    }

    &:hover {
      color: $themeColor;
      opacity: 1;
      &::before {
        width: 100%;
      }
      &::after {
        background: url(../images/common/bg_arrow-long-blue.svg) no-repeat 50% 50%;
      }
    }
  }
}

.mod-btn-02 {
  display: inline-block;
  color: $themeColor;
  font-weight: bold;
  font-size: (13 / $baseFont) * 100%;
  padding: 10px 20px;
  border: 1px solid $themeColor;
  background-color: transparent;
  transition: all .3s ease-in-out;
  text-decoration: none;
  min-width: 220px;
  border-radius: 0 !important;

  &:active {
    display: inline-block;
    color: $themeColor;
    font-weight: bold;
    font-size: (13 / $baseFont) * 100%;
    padding: 10px 20px;
    border: 1px solid $themeColor;
    background-color: transparent;
    text-decoration: none;
    min-width: 220px;
  }


  @media only screen and (min-width: 768px) {
    padding: 12px 30px;
    font-size: (14 / $baseFont) * 100%;
    &:hover {
      cursor: pointer;
      background: #ccc;
      border-color: #ccc;
      opacity: 1;
    }
  }
  @include sphoneL {

  }
}


@charset "utf-8";
/*
#overview
base
*/

body {
  &.js-fixed {
    width: 100%;
    position: fixed;
    overflow: hidden;
    backface-visibility: hidden;
    z-index: -1;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .6);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
    }
    #header {
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
      }
    }
  }
}

#header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  @include tabletL {
    padding: 20px 0;
    display: block;
  }
  &.is-fixed {
    .header__navi-link--contact {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .header-logo {
      width: 200px;
      height: 41px;
    }
    .header__navi-link {
      &::after {
        bottom: 5px;
      }
    }
  }
}

.header-btn {
  width: 30px;
  height: 22px;
  position: absolute;
  top: 35px;
  right: 20px;
  z-index: 30;
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  &:focus {
    outline: none;
  }
  &.js-active {
    .header-btn-line {
      animation-duration: 0.5s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
      &:first-child {
        animation-name: menu-line-top;
      }
      &:nth-child(2) {
        animation-name: menu-line-middle;
      }
      &:last-child {
        animation-name: menu-line-bottom;
      }
    }
  }
  @include tabletL {
    display: block;
  }
  @include tabletP {
    top: 30px;
  }
}

.header-btn-line {
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  background-color: $themeColor;

  &:first-child {
    top: 0;
  }

  &:nth-child(2) {
    top: 10px;
  }

  &:last-child {
    bottom: 0;
  }
}

@keyframes menu-line-top {
  0% {
    top: 0;
  }
  30% {
    top: 10px;
  }
  60% {
    top: 10px;
  }
  100% {
    top: 9px;
    transform: translateY(0px) rotate(45deg);
  }
}

@keyframes menu-line-middle {
  0% {
    top: 10px;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes menu-line-bottom {
  0% {
    bottom: 0;
  }
  30% {
    bottom: 10px;
  }
  60% {
    bottom: 10px;
  }
  100% {
    bottom: 11px;
    transform: translateY(0px) rotate(-45deg);
  }
}

.header-logo {
  width: 270px;
  height: 56px;
  display: block;
  overflow: hidden;
  background: url(../images/common/logo.svg) no-repeat 0 50%;
  margin: 0 3%;
  transition: all .3s ease-in-out;
  @include underWide {
    width: 200px;
    height: 41px;
  }
}
.header-logo-link {
  width: 100%;
  height: 0;
  padding-top: 56px;
  display: block;
  overflow: hidden;
  @include underWide {
    padding-top: 41px;
  }
}

.header__navi {
  width: 60%;
  @include underWide {
    width: 70%;
  }
  @include tabletL {
    position: fixed;
    top: 0;
    right: -100%;
    width: 60%;
    height: 100%;
    background-color: #fff;
    z-index: 5;
    text-align: center;
    padding-top: 80px;
  }

  @include sphoneP {
    width: 80%;
    padding-top: 40px;
  }
}

.header__navi-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @include tabletL {
    display: block;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include sphoneP {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.header__navi-item {
  margin-right: 2.6%;

  @include tabletL {
    margin: 10px auto;
  }

  @include sphoneL {
    margin: 0 auto;
  }
}

.header__navi-link {
  text-decoration: none;
  color: $mainColor;
  font-weight: bold;
  display: block;
  text-align: center;
  position: relative;
  padding: 10px 0;
  &::after {
    content: '';
    width: 0;
    height: 3px;
    display:block;
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all .3s ease-in-out;
  }
  &:hover {
    opacity: 1;
  }

  @include tabletL {
    margin-right: 0;
    font-size: (16 / $baseFont) * 100%;
    color: $themeColor;
    padding: 20px 0;
    &::after {
      content: none;
    }
  }
}

.header__navi-link:hover,
.header__navi-link.js-active {
  &::after {
    background-color: $themeColor;
    width: 100%;
  }
}

.header__navi-link--contact {
  height: 100%;
  background-color: $themeColor;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  display: block;
  padding: 50px 1.5em;
  text-align: center;
  transition: all .5s ease-in-out;
  &:hover {
    background-color: #E9F0F4;
    color: $themeColor;
  }
  @include tabletL {
    font-size: (16 / $baseFont) * 100%;
    padding: 15px 0;
  }
  @include sphoneL {
    margin-top: 10px;
  }
}



/*
#styleguide
container

```
<div class="container">
左右にpadding15pxつきます
</div>
```
*/

.container {
	margin-right: auto;
	margin-left: auto;
	padding-right: ($containerPadding)+px;
	padding-left:  ($containerPadding)+px;

}

#footer {
  text-align: center;
  padding: 30px 0;
  border-top: 1px solid #eee;

  @include sphoneL {
    padding: 20px 0;
  }
}

.footer-copyright {
  font-family: 'Open Sans', sans-serif;
  font-size: (13 / $baseFont) * 100%;
}

#contents {
  margin-top: 125px;
  @include tabletL {
    margin-top: 80px;
  }
}

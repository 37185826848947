@import url(font-awesome.min.css);

@import url("https://fonts.googleapis.com/css?family=Roboto");

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

/* Reset
------------------------------------------------------------*/

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

address,
em {
	font-style: normal;
}

strong,
th {
	font-weight: normal;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

th {
	text-align: left;
}

hr,
legend {
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}

img,
fieldset {
	border: 0;
}

img {
	border: 0;
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

li {
	list-style-type: none;
}

input[type="submit"],
button,
label,
select {
	cursor: pointer;
}

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* link
------------------------------------------------------------*/

a {
	color: #00226C;
	text-decoration: underline;
}

a:hover {
	text-decoration: none;
	opacity: 0.8;
}

button:hover {
	opacity: 0.8;
}

/* Fonts
------------------------------------------------------------*/

html {
	font-size: 62.5%;
}

/* 10px base */

body {
	-webkit-text-size-adjust: 100%;
	word-wrap: break-word;
	overflow-wrap: break-word;
	font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
	line-height: 1.8;
	color: #333;
}

body > div,
input,
button,
textarea,
select {
	font-size: 14px;
	font-size: 1.4rem;
}

input,
button,
textarea,
select {
	line-height: 1.8;
	color: #333;
	font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.roboto,
.mod-title-02-en,
.mv__text-title,
.sandc-title-en,
.flow__item-title-en,
.flow-number {
	font-family: 'Roboto', sans-serif;
}

/* form
------------------------------------------------------------*/

select {
	padding: 10px;
	border-radius: 5px;
}

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select {
	padding: 15px;
	border-radius: 5px;
}

textarea.error,
input[type="text"].error,
input[type="password"].error,
input[type="email"].error,
select.error {
	background-color: #fff2f2;
	border: solid 2px #E33E41;
}

/* radio
-------------*/

input[type="radio"] {
	display: none;
}

input[type="radio"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 24px;
	cursor: pointer;
	width: 100%;
}

input[type="radio"] + label::before,
input[type="radio"] + label::after {
	content: '';
	top: 50%;
	position: absolute;
	border-radius: 100%;
	transition: all .2s;
}

input[type="radio"] + label::before {
	width: 16px;
	height: 16px;
	margin-top: -9px;
	left: 0;
	background: #EEE;
	border: 1px solid #ccc;
}

input[type="radio"].error + label::before {
	border: 1px solid #E33E41;
	background-color: #fff2f2;
}

input[type="radio"] + label:hover::before {
	background: #FEFEFE;
}

input[type="radio"] + label::after {
	opacity: 0;
	left: 3px;
	width: 10px;
	height: 10px;
	margin-top: -6px;
	background: #3498DB;
	transform: scale(0.5);
}

input[type="radio"]:checked + label::before {
	background: #fff;
	border: 1px solid #3498DB;
}

input[type="radio"]:checked + label::after {
	opacity: 1;
	transform: scale(1);
}

/* checkbox
-------------*/

input[type="checkbox"] {
	display: none;
}

input[type="checkbox"] + label {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 22px;
	cursor: pointer;
	-webkit-transition: all .2s;
	transition: all .2s;
}

input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after {
	position: absolute;
	content: '';
}

input[type="checkbox"] + label::before {
	top: 50%;
	left: 0;
	width: 14px;
	height: 14px;
	margin-top: -8px;
	background: #f4f4f4;
	border: 1px solid #ccc;
	border-radius: 3px;
}

input[type="checkbox"] + label::after {
	opacity: 0;
	top: 50%;
	left: 3px;
	width: 8px;
	height: 4px;
	margin-top: -4px;
	border-left: 2px solid #3498DB;
	border-bottom: 2px solid #3498DB;
	transform: rotate(-45deg) scale(0.5);
}

input[type="checkbox"] + label:hover::before {
	background: #fff;
}

input[type="checkbox"]:checked + label::before {
	background: #fff;
	border: 1px solid #3498DB;
}

input[type="checkbox"]:checked + label::after {
	opacity: 1;
	transform: rotate(-45deg) scale(1);
}

.text-center {
	text-align: center;
}

/*
#overview
base
*/

body.js-fixed {
	width: 100%;
	position: fixed;
	overflow: hidden;
	backface-visibility: hidden;
	z-index: -1;
}

body.js-fixed::after {
	content: "";
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
}

body.js-fixed #header::after {
	content: "";
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
}

#header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	transition: all .3s ease-in-out;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
}

#header.is-fixed .header__navi-link--contact {
	padding-top: 20px;
	padding-bottom: 20px;
}

#header.is-fixed .header-logo {
	width: 200px;
	height: 41px;
}

#header.is-fixed .header__navi-link::after {
	bottom: 5px;
}

.header-btn {
	width: 30px;
	height: 22px;
	position: absolute;
	top: 35px;
	right: 20px;
	z-index: 30;
	background: transparent;
	border: none;
	cursor: pointer;
	display: none;
}

.header-btn:focus {
	outline: none;
}

.header-btn.js-active .header-btn-line {
	animation-duration: 0.5s;
	animation-timing-function: ease-in;
	animation-fill-mode: forwards;
}

.header-btn.js-active .header-btn-line:first-child {
	animation-name: menu-line-top;
}

.header-btn.js-active .header-btn-line:nth-child(2) {
	animation-name: menu-line-middle;
}

.header-btn.js-active .header-btn-line:last-child {
	animation-name: menu-line-bottom;
}

.header-btn-line {
	display: block;
	width: 100%;
	height: 3px;
	position: absolute;
	background-color: #00226C;
}

.header-btn-line:first-child {
	top: 0;
}

.header-btn-line:nth-child(2) {
	top: 10px;
}

.header-btn-line:last-child {
	bottom: 0;
}

.header-logo {
	width: 270px;
	height: 56px;
	display: block;
	overflow: hidden;
	background: url(../images/common/logo.svg) no-repeat 0 50%;
	margin: 0 3%;
	transition: all .3s ease-in-out;
}

.header-logo-link {
	width: 100%;
	height: 0;
	padding-top: 56px;
	display: block;
	overflow: hidden;
}

.header__navi {
	width: 60%;
}

.header__navi-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.header__navi-item {
	margin-right: 2.6%;
}

.header__navi-link {
	text-decoration: none;
	color: #333;
	font-weight: bold;
	display: block;
	text-align: center;
	position: relative;
	padding: 10px 0;
}

.header__navi-link::after {
	content: '';
	width: 0;
	height: 3px;
	display: block;
	background-color: transparent;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	transition: all .3s ease-in-out;
}

.header__navi-link:hover {
	opacity: 1;
}

.header__navi-link:hover::after,
.header__navi-link.js-active::after {
	background-color: #00226C;
	width: 100%;
}

.header__navi-link--contact {
	height: 100%;
	background-color: #00226C;
	color: #fff;
	font-weight: bold;
	text-decoration: none;
	display: block;
	padding: 50px 1.5em;
	text-align: center;
	transition: all .5s ease-in-out;
}

.header__navi-link--contact:hover {
	background-color: #E9F0F4;
	color: #00226C;
}

/*
#styleguide
container

```
<div class="container">
左右にpadding15pxつきます
</div>
```
*/

.container {
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
}

#footer {
	text-align: center;
	padding: 30px 0;
	border-top: 1px solid #eee;
}

.footer-copyright {
	font-family: 'Open Sans', sans-serif;
	font-size: 92.85714%;
}

#contents {
	margin-top: 125px;
}

/* CSS Document */

.container {
	max-width: 970px;
}

.modSpOnly {
	display: none;
}

/*
#overview
modTitle
*/

/*
#styleguide
xxxxx

```
コード
```
*/

.mod-title-01 {
	color: #00226C;
	font-size: 142.85714%;
	display: inline-block;
}

.mod-title-01::after {
	content: '';
	display: block;
	width: 100%;
	height: 1px;
	background-color: #00226C;
}

.mod-title-02 {
	font-size: 114.28571%;
	color: #00226C;
	padding-left: 30px;
	position: relative;
}

.mod-title-02-en {
	font-size: 437.5%;
	display: block;
	line-height: 1.1;
}

.mod-title-02-en::before {
	content: '';
	display: block;
	width: 20px;
	height: 2px;
	background-color: #00226C;
	position: absolute;
	left: 0;
	top: 40px;
}

.mod-title-03 {
	font-size: 142.85714%;
	color: #00226C;
	position: relative;
	padding-left: 30px;
	font-weight: bold;
}

.mod-title-03::before {
	content: '';
	display: block;
	width: 20px;
	height: 2px;
	background-color: #00226C;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

/*
#overview
modTxt
*/

/*
#styleguide
xxxxx

```
コード
```
*/

/*
#overview
modBtn
*/

/*
#styleguide
xxxxx

```
コード
```
*/

.mod-btn-01 {
	background-color: #00226C;
	color: #fff;
	font-size: 114.28571%;
	font-weight: bold;
	display: inline-block;
	text-decoration: none;
	position: relative;
	padding: 14px 40px;
}

.mod-btn-01 span {
	position: relative;
	z-index: 2;
}

.mod-btn-01::after {
	width: 100px;
}

.mod-btn-01::after {
	content: '';
	display: inline-block;
	width: 150px;
	height: 13px;
	background: url(../images/common/bg_arrow-long-white.svg) no-repeat 50% 50%;
	background-size: 100% auto;
	margin-left: 20px;
	position: relative;
	z-index: 2;
}

.mod-btn-02 {
	display: inline-block;
	color: #00226C;
	font-weight: bold;
	font-size: 92.85714%;
	padding: 10px 20px;
	border: 1px solid #00226C;
	background-color: transparent;
	transition: all .3s ease-in-out;
	text-decoration: none;
	min-width: 220px;
	border-radius: 0 !important;
}

.mod-btn-02:active {
	display: inline-block;
	color: #00226C;
	font-weight: bold;
	font-size: 92.85714%;
	padding: 10px 20px;
	border: 1px solid #00226C;
	background-color: transparent;
	text-decoration: none;
	min-width: 220px;
}

/*
#overview
modBlock
*/

/*
#styleguide

```
コード
```
*/

/*
#overview
modTable
*/

/*
#styleguide
xxxxx

```
コード
```
*/

/*
#overview
modList
*/

/*
#styleguide
modList　modIndent

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
<ul class="modList01">
    <li>Illustrator に原寸で配置したサイズでの解像度が350dpiになるようにしてください。</li>
    <li> 拡大縮小率70%〜141％を超えると画質が劣化するのでご注意ください。</li>
</ul>
```
*/

.modListNormal {
	margin: 0 0 0 2em;
}

ul.modListNormal li:before {
	content: "\0030fb";
	margin-left: -2em;
	display: inline-block;
	width: 2em;
	text-align: center;
}

ol.modListNormal li {
	list-style: decimal;
}

/* indent */

.modIndent01 li {
	text-indent: -1em;
	margin: 0 0 0 1em;
}

.modIndent02 li {
	text-indent: -2.5em;
	margin: 0 0 0 2.5em;
}

/* フォーム
***************************************/

.modDlForm {
	margin: 0 0 20px;
}

.modDlForm dt {
	float: left;
	clear: both;
	width: 16em;
	padding: 33px 0 20px 63px;
	position: relative;
}

.modDlForm dt.required:before,
.modDlForm dt.any:before {
	position: absolute;
	left: 0;
	top: 33px;
	color: #FFF;
	font-size: 11px;
	font-size: 1.1rem;
	padding: 2px 10px;
}

.modDlForm dt.required:before {
	content: "必須";
	background: #E33E41;
}

.modDlForm dt.any:before {
	background: #999999;
	content: "任意";
}

.modDlForm dd {
	border-bottom: solid 1px #ccc;
	padding: 10px 0;
	padding-left: 17em;
}

.modDlForm dd .parts {
	padding: 10px 0;
}

.modDlForm dd .parts textarea,
.modDlForm dd .parts input[type="text"],
.modDlForm dd .parts input[type="email"] {
	width: 100%;
}

.modDlForm dd .parts.radio_inline > div,
.modDlForm dd .parts.check_inline > div {
	display: inline-block;
}

.modDlForm dd .parts.name {
	display: flex;
	justify-content: space-between;
}

.modDlForm dd .parts.name > div {
	width: 48.5%;
}

.modDlForm dd .parts.post,
.modDlForm dd .parts.tel {
	display: flex;
}

.modDlForm dd .parts.post .hyphen,
.modDlForm dd .parts.tel .hyphen {
	padding: 10px 10px 0 10px;
}

.modDlForm dd .parts.password input {
	width: 100%;
	max-width: 300px;
}

.modDlForm dd .parts.password .text {
	padding: 10px 0;
}

.modDlForm .validationError {
	color: #E33E41;
}

/*
#overview
other
*/

/*
#styleguide
xxxxx

```
コード
```
*/

/*↓消さないこと*/

.modLoading {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.8);
}

.modLoading i {
	font-size: 24px;
	font-size: 2.4rem;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -0.5em;
	margin-top: -0.5em;
}

/*↑消さないこと*/

.bg-grey {
	background-color: #eee;
}

/* Slider */

.slick-slider {
	position: absolute;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	right: 0;
	top: 0;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
	z-index: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-list img {
	width: 100%;
	display: block;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-track:before,
.slick-track:after {
	content: "";
	display: table;
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;
}

[dir="rtl"] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}

/* Slider */

.slick-loading .slick-list {
	background: #fff url("../images/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */

@font-face {
	font-family: "slick";
	src: url("../fonts/slick.eot");
	src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
	font-weight: normal;
	font-style: normal;
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	outline: none;
	background: transparent;
	color: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
	font-family: "slick";
	font-size: 20px;
	line-height: 1;
	color: white;
	opacity: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: -25px;
}

[dir="rtl"] .slick-prev {
	left: auto;
	right: -25px;
}

.slick-prev:before {
	content: "←";
}

[dir="rtl"] .slick-prev:before {
	content: "→";
}

.slick-next {
	right: -25px;
}

[dir="rtl"] .slick-next {
	left: -25px;
	right: auto;
}

.slick-next:before {
	content: "→";
}

[dir="rtl"] .slick-next:before {
	content: "←";
}

/* Dots */

.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	list-style: none;
	display: block;
	text-align: left;
	padding: 0;
	margin: 0;
	width: 100%;
	z-index: 3;
}

.slick-dots li {
	position: relative;
	display: inline-block;
	height: 20px;
	width: 20px;
	margin: 0;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	border: 0;
	background: transparent;
	display: block;
	height: 20px;
	width: 20px;
	outline: none;
	line-height: 0px;
	font-size: 0px;
	color: transparent;
	padding: 5px;
	cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}

.slick-dots li button:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "•";
	width: 20px;
	height: 20px;
	font-family: "slick";
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	color: black;
	opacity: 0.25;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	color: #00226C;
	opacity: 1;
}

.mv {
	position: relative;
	width: 100%;
	height: auto;
	padding-bottom: 12%;
}

.mv::before {
	content: '';
	display: block;
	width: 46%;
	height: 80%;
	background-color: rgba(255, 255, 255, 0.9);
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 1;
}

.mv__scroll {
	position: absolute;
	bottom: 10%;
	left: 20px;
	z-index: 3;
}

.mv__inner {
	padding-top: 13%;
}

.mv__slider {
	width: 75%;
}

.mv__slider.is-show::after {
	width: 0;
}

.mv_slider-name {
	display: block;
	text-align: right;
	margin-top: 10px;
	padding-right: 10px;
}

.mv_slider-name br {
	display: none;
}

.mv__text {
	position: relative;
	z-index: 3;
	height: 100%;
}

.mv__text-title {
	font-size: 857.14286%;
	line-height: 1;
	color: #00226C;
	text-transform: uppercase;
	letter-spacing: .03em;
	margin-bottom: 20px;
	display: block;
}

.mv__text-subtitle {
	color: #00226C;
	font-size: 107.14286%;
	display: none;
}

.mv__text-subtitle::before {
	content: '';
	display: inline-block;
	width: 20px;
	height: 1px;
	vertical-align: middle;
	margin-right: 10px;
	background-color: #00226C;
	margin-bottom: 10px;
}

.mv__text-message {
	font-size: 114.28571%;
}

.mv-slider-dots {
	margin-top: 40px;
}

[class*="sandc--"] {
	padding: 100px 0;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: cover;
}

.sandc-title {
	margin-bottom: 20px;
	color: #fff;
	font-size: 214.28571%;
	position: relative;
}

.sandc-title-en {
	display: block;
	color: rgba(255, 255, 255, 0.25);
	font-size: 333.33333%;
	letter-spacing: -0.03em;
	line-height: 1;
}

.sandc-title-en br {
	display: none;
}

.sandc-message {
	color: #fff;
	font-size: 114.28571%;
}

.sandc--01 {
	background-image: url(../images/home/bg_solution01.jpg);
}

.sandc__diagram,
.sandc__timing {
	display: flex;
	justify-content: space-between;
	margin-top: 60px;
}

.sandc-diagram__item {
	width: 45%;
	flex-basis: 45%;
}

.sandc-diagram__item:last-child img {
	width: 85%;
	height: auto;
}

.sandc-diagram-title {
	font-size: 142.85714%;
	color: #fff;
	border-bottom: 1px solid #fff;
	margin-bottom: 20px;
	display: inline-block;
}

.sandc--02 {
	background-image: url(../images/home/bg_solution02.jpg);
}

.sandc-timing__item {
	width: 48%;
	flex-basis: 48%;
	color: #fff;
}

.sandc__timing-title {
	font-size: 142.85714%;
	text-align: center;
	margin-bottom: 20px;
}

.sandc__timing-title b {
	border-bottom: 1px solid #fff;
	padding-bottom: 10px;
}

.sandc__timing-issue {
	display: table;
	border-collapse: separate;
	border-spacing: 8px 0;
	align-items: center;
}

.sandc__timing-issue-item {
	display: table-cell;
	vertical-align: middle;
	width: 30%;
	border: 3px solid #fff;
	font-size: 114.28571%;
	text-align: center;
	padding: 40px 5px;
	max-height: 140px;
}

.sandc__timing-arrow {
	text-align: center;
	margin: 10px 0;
}

.sandc__timing-solution {
	font-size: 171.42857%;
	text-align: center;
}

.flow {
	padding: 120px 0;
}

.flow-title {
	margin-bottom: 40px;
}

.flow-title br {
	display: none;
}

.flow__01 {
	position: relative;
}

.flow__items {
	width: 72%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	padding-top: 50px;
}

.flow__item {
	width: 45%;
}

[class*="flow__item-chain--"] {
	display: block;
	width: 100%;
	height: 0;
	padding-top: 110px;
	overflow: hidden;
}

.flow__item-chain--left {
	background: url(../images/home/img_flow-arrow-left_pc.png) no-repeat 80% 50%;
}

.flow__item-chain--right {
	background: url(../images/home/img_flow-arrow-right_pc.png) no-repeat 70% 50%;
}

.flow__company-name {
	width: 72%;
	background: #00226C;
	margin: 0 auto 100px;
	padding: 25px 0;
	text-align: center;
}

.flow__company-name img {
	width: 300px;
	height: auto;
}

.flow-item__inner {
	background-color: #fff;
	padding: 40px 40px 30px;
}

.flow__item-title {
	font-size: 142.85714%;
	line-height: 1.4;
	margin-bottom: 10px;
	text-align: center;
}

.flow__item-title-en {
	font-size: 90%;
	color: #00226C;
	display: block;
	margin-bottom: 5px;
}

.flow__item-list {
	padding: 0 20px;
}

.flow__item-item {
	font-size: 114.28571%;
	position: relative;
	padding-left: 18px;
	text-align-last: left;
}

.flow__item-item::before {
	content: '';
	display: block;
	width: 12px;
	height: 2px;
	background-color: #00226C;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

.flow__01-title {
	font-size: 142.85714%;
	position: absolute;
	left: 0;
	top: 0;
}

.flow-number {
	font-size: 180%;
	display: block;
	color: #00226C;
	line-height: 1.2;
	margin-bottom: 8px;
}

.flow-number::after {
	content: '';
	display: block;
	width: 1.1em;
	height: 1px;
	background-color: #00226C;
}

.flow__steps {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.flow__steps-item {
	font-size: 128.57143%;
	line-height: 1.4;
	position: relative;
	flex-basis: 16%;
}

.flow__steps-item .flow-number {
	margin-bottom: 10px;
}

.flow__steps-item::after {
	content: '';
	display: block;
	position: absolute;
	right: 25px;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 22px;
	height: 22px;
	border-top: 1px solid #00226C;
	border-right: 1px solid #00226C;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.flow__steps-item:last-child::after {
	content: none;
}

.case {
	padding: 100px 0 40px;
}

.case-title {
	margin-bottom: 60px;
}

.case__pickup {
	margin-bottom: 90px;
}

.case-pickup__item {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}

.case-pickup__image,
.case-pickup__detail {
	width: 49%;
}

.case-pickup__image img {
	width: 100%;
}

.case-pickup-title {
	font-size: 128.57143%;
	margin-bottom: 30px;
}

.case-pickup-address {
	font-size: 77.77778%;
	color: #666;
	display: block;
}

.case-pickup-point {
	background-color: #eee;
	display: inline-block;
	padding: 2px 15px;
}

.case-pickup-text {
	font-size: 114.28571%;
	margin: 5px 0 20px;
}

.case__items {
	margin: 0 0 80px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.case__items::before {
	content: "";
	display: block;
	width: 24%;
	order: 1;
}

.case__items::after {
	content: "";
	display: block;
	width: 24%;
}

.case__item {
	width: 24%;
	flex-basis: 24%;
	margin: 20px 0 0;
}

.case__item img {
	width: 100%;
	height: auto;
}

.case__item-title {
	font-size: 114.28571%;
}

.case__item-address {
	font-size: 87.5%;
	color: #666;
	display: block;
}

.company {
	padding: 100px 0 160px;
}

.company-title {
	margin-bottom: 60px;
}

.company__inner {
	display: flex;
	justify-content: space-between;
}

.company__box {
	width: 33%;
	padding: 0 0 30px 10px;
	border-right: 1px solid #ccc;
}

.company__box:last-child {
	border-right: none;
}

.company__detail {
	margin-top: -30px;
}

.company__detail-name {
	font-weight: bold;
	margin-bottom: 12px;
	margin-top: 30px;
	font-size: 114.28571%;
}

h3.company__detail-name {
	margin-top: 0;
}

.company__detail-value,
.company__partner {
	font-size: 114.28571%;
}

.company__partner-item {
	margin-bottom: 15px;
}

.company__partner-item a {
	display: block;
}

.contact {
	padding-bottom: 100px;
}

.contact .container {
	padding: 100px 200px;
}

.contact-title {
	color: #00226C;
	font-size: 114.28571%;
	text-align: center;
	margin-bottom: 40px;
}

.contact-title-en {
	display: block;
	font-size: 425%;
	line-height: 1;
}

.contact-message {
	text-align: center;
	font-size: 114.28571%;
}

.contact-memo {
	text-align: center;
	margin: 20px 0 60px;
}

.form-confirm {
	margin-top: 100px;
	margin-bottom: -80px;
}

.form-confirm .form__submit-area {
	margin-top: 100px;
}

.form-set {
	margin-bottom: 30px;
}

.form-set-label {
	display: block;
	font-size: 114.28571%;
	font-weight: bold;
	margin-bottom: 8px;
}

.form-set-label .require {
	color: #EB5D5D;
	font-size: 75%;
	border: 1px solid #EB5D5D;
	padding: 3px 10px;
	margin-left: 10px;
}

.form-set-label .annotation {
	color: #666;
	font-size: 87.5%;
	font-weight: normal;
	margin-left: 15px;
}

.form-set-confirm {
	display: block;
	font-size: 114.28571%;
	margin-bottom: 50px;
}

.form-set-text {
	display: block;
	width: 100%;
	border: none;
}

.form-set-text.has-error {
	border: 2px solid #EB5D5D;
}

.form-message {
	height: 20em;
}

.form__submit-area .text-center {
	margin-bottom: 30px;
}

@media only screen and (min-width: 768px) {

.mod-btn-01 {
	padding: 20px 80px;
}

.mod-btn-01::before {
	content: '';
	display: block;
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #fff;
	z-index: 0;
	transition: all .5s ease-in-out;
}

.mod-btn-01:hover {
	color: #00226C;
	opacity: 1;
}

.mod-btn-01:hover::before {
	width: 100%;
}

.mod-btn-01:hover::after {
	background: url(../images/common/bg_arrow-long-blue.svg) no-repeat 50% 50%;
}

.mod-btn-02 {
	padding: 12px 30px;
	font-size: 100%;
}

.mod-btn-02:hover {
	cursor: pointer;
	background: #ccc;
	border-color: #ccc;
	opacity: 1;
}

}

@media only screen and (min-width: 1200px) {

.container {
	max-width: 1160px;
}

}

@media only screen and (max-width: 1500px) {

.mv {
	padding-bottom: 6%;
}

}

@media only screen and (max-width: 1200px) {

.header-logo {
	width: 200px;
	height: 41px;
}

.header-logo-link {
	padding-top: 41px;
}

.header__navi {
	width: 70%;
}

.mv__text-title {
	font-size: 785.71429%;
}

.flow__items {
	width: 80%;
}

.flow__item {
	width: 45%;
}

.flow__item-title br {
	display: none;
}

.flow__steps-item {
	font-size: 114.28571%;
}

}

@media only screen and (max-width: 992px) {

#header {
	padding: 20px 0;
	display: block;
}

.header-btn {
	display: block;
}

.header__navi {
	position: fixed;
	top: 0;
	right: -100%;
	width: 60%;
	height: 100%;
	background-color: #fff;
	z-index: 5;
	text-align: center;
	padding-top: 80px;
}

.header__navi-list {
	display: block;
	padding-left: 40px;
	padding-right: 40px;
}

.header__navi-item {
	margin: 10px auto;
}

.header__navi-link {
	margin-right: 0;
	font-size: 114.28571%;
	color: #00226C;
	padding: 20px 0;
}

.header__navi-link::after {
	content: none;
}

.header__navi-link--contact {
	font-size: 114.28571%;
	padding: 15px 0;
}

#contents {
	margin-top: 80px;
}

.mv {
	padding-bottom: 6%;
}

.mv::before {
	content: '';
	display: block;
	width: 55%;
}

.mv_slider-name br {
	display: block;
}

.mv__text {
	padding-left: 60px;
}

.mv__text-title {
	font-size: 571.42857%;
}

.mv__text-message {
	font-size: 100%;
}

.sandc-title-en {
	font-size: 266.66667%;
}

.flow__items {
	padding-top: 0;
}

.flow__01-title {
	position: static;
	margin-bottom: 10px;
}

.flow__steps-item::after {
	right: 10px;
}

.contact .container {
	padding: 100px;
}

}

@media only screen and (max-width: 768px) {

.header-btn {
	top: 30px;
}

.container {
	max-width: 750px;
}

.modPcOnly {
	display: none;
}

.modSpOnly {
	display: block;
}

.modDlForm dt {
	float: none;
	width: auto;
	padding: 33px 0 0 63px;
}

.modDlForm dd {
	padding: 10px 0;
}

.slick-slider {
	position: relative;
}

.slick-dotted.slick-slider {
	margin-bottom: 20px;
}

.slick-dots {
	text-align: center;
	margin-top: 10px;
}

.slick-dots li button:before {
	font-size: 8px;
}

.mv::before {
	content: none;
}

.mv__scroll {
	bottom: 5%;
}

.mv__inner {
	padding-top: 0;
}

.mv__inner.container {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

.mv__slider {
	width: 100%;
}

.mv__slider-item {
	position: relative;
}

.mv_slider-name {
	position: absolute;
	bottom: 10px;
	right: 20px;
	color: #fff;
	z-index: 2;
	font-weight: bold;
	text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
}

.mv__text {
	padding-left: 80px;
	padding-right: 80px;
}

.sandc-title-en br {
	display: block;
}

.sandc-diagram__item {
	width: 48%;
}

.sandc-diagram__item:last-child img {
	width: 95%;
	height: auto;
}

.sandc__timing {
	display: block;
}

.sandc-timing__item {
	width: 100%;
}

.sandc-timing__item:first-child {
	margin-bottom: 60px;
}

.flow {
	padding: 80px 0;
}

.flow__items {
	width: 100%;
}

.flow__company-name {
	width: 100%;
}

.flow__steps {
	flex-wrap: wrap;
	justify-content: center;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
}

.flow__steps-item {
	width: 33%;
	flex-basis: 33%;
	margin-bottom: 5px;
}

.flow__steps-item:nth-child(3)::after {
	content: none;
}

.flow__steps-item::after {
	right: 25px;
}

.case {
	padding: 80px 0;
}

.case-pickup__item {
	display: block;
}

.case-pickup__image,
.case-pickup__detail {
	width: 100%;
}

.case__items {
	margin-bottom: 40px;
}

.case__item {
	width: 48%;
	flex-basis: 48%;
	margin-bottom: 20px;
}

.case__item {
	width: 100%;
}

.company {
	padding: 0 0 50px;
}

.company__inner {
	display: block;
}

.company__box {
	width: 100%;
	border-right: none;
}

.company__detail {
	margin-top: 0;
}

.company__detail-name {
	margin-top: 20px;
}

.contact .container {
	padding: 60px 40px;
}

.form-confirm {
	margin-top: 60px;
}

.form-confirm .form__submit-area {
	margin-top: 60px;
}

}

@media only screen and (max-width: 600px) {

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select {
	padding: 10px;
}

.header__navi-item {
	margin: 0 auto;
}

.header__navi-link--contact {
	margin-top: 10px;
}

#footer {
	padding: 20px 0;
}

[class*="sandc--"] {
	padding: 60px 0;
}

.sandc-title {
	font-size: 171.42857%;
}

.sandc-title-en {
	font-size: 250%;
}

.sandc-title-en {
	font-size: 240%;
}

.sandc__timing-issue-item {
	font-size: 100%;
}

.sandc__timing-solution {
	font-size: 128.57143%;
}

.flow {
	padding: 60px 0;
}

.flow__item {
	width: 48%;
}

[class*="flow__item-chain--"] {
	padding-top: 200px;
}

.flow__item-chain--left {
	background: url(../images/home/img_flow-arrow-left_sp.png) no-repeat 50% 50%;
}

.flow__item-chain--right {
	background: url(../images/home/img_flow-arrow-right_sp.png) no-repeat 50% 50%;
}

.flow__company-name {
	margin-bottom: 40px;
}

.flow__company-name img {
	width: 240px;
}

.flow-item__inner {
	padding: 30px 20px 20px;
}

.flow__item-title {
	font-size: 114.28571%;
}

.flow__item-list {
	padding: 0;
}

.flow__item-item {
	font-size: 100%;
}

.flow__01-title {
	font-size: 114.28571%;
}

.flow__steps {
	width: 100%;
}

.flow__steps-item {
	font-size: 85.71429%;
}

.flow__steps-item::after {
	width: 14px;
	height: 14px;
	right: 15px;
}

.case {
	padding: 40px 0;
}

.case-title {
	margin-bottom: 40px;
}

.case__pickup {
	margin-bottom: 40px;
}

.case__items {
	margin-bottom: 20px;
}

.company {
	padding-bottom: 30px;
}

.company-title {
	margin-bottom: 40px;
}

.contact {
	padding-bottom: 40px;
}

.contact .container {
	padding: 40px 20px;
}

.contact-memo {
	margin-bottom: 30px;
}

.form-confirm {
	margin-bottom: -40px;
}

.form-set-confirm {
	margin-bottom: 30px;
}

.form-message {
	height: 10em;
}

}

@media only screen and (max-width: 480px) {

.header__navi {
	width: 80%;
	padding-top: 40px;
}

.header__navi-list {
	padding-left: 20px;
	padding-right: 20px;
}

.mod-title-01 {
	font-size: 128.57143%;
}

.mod-title-02-en {
	font-size: 281.25%;
}

.mod-title-03 {
	font-size: 128.57143%;
}

.modDlForm dd .parts.post .hyphen,
.modDlForm dd .parts.tel .hyphen {
	padding: 10px 5px 0 5px;
}

.modDlForm dd .parts.password input {
	max-width: none;
}

.mv__scroll {
	left: 10px;
}

.mv__text {
	padding-left: 45px;
	padding-right: 20px;
}

.mv__text-title {
	font-size: 464.28571%;
}

.mv__text-subtitle {
	display: block;
}

[class*="sandc--"] {
	padding: 40px 0;
}

.sandc-title {
	font-size: 142.85714%;
}

.sandc__diagram,
.sandc__timing {
	display: block;
	margin-top: 40px;
}

.sandc-diagram__item {
	width: 100%;
}

.sandc-diagram__item:first-child {
	margin-bottom: 30px;
}

.sandc__timing-issue {
	display: block;
}

.sandc__timing-issue-item {
	display: block;
	width: 100%;
	padding: 12px 10px;
	margin-bottom: 20px;
}

.sandc__timing-issue-item br {
	display: none;
}

.flow {
	padding: 40px 0;
}

.flow-title br {
	display: block;
}

.flow-item__inner {
	padding: 20px 10px 10px;
}

.case__items {
	display: block;
}

.contact-title-en {
	font-size: 281.25%;
}

}

@keyframes menu-line-top {

0% {
	top: 0;
}

30% {
	top: 10px;
}

60% {
	top: 10px;
}

100% {
	top: 9px;
	transform: translateY(0px) rotate(45deg);
}

}

@keyframes menu-line-middle {

0% {
	top: 10px;
}

30% {
	opacity: 0;
}

100% {
	opacity: 0;
}

}

@keyframes menu-line-bottom {

0% {
	bottom: 0;
}

30% {
	bottom: 10px;
}

60% {
	bottom: 10px;
}

100% {
	bottom: 11px;
	transform: translateY(0px) rotate(-45deg);
}

}


@charset "utf-8";

/*
#overview
modTitle
*/

/*
#styleguide
xxxxx

```
コード
```
*/

.mod-title-01 {
  color: $themeColor;
  font-size: (20 / $baseFont) * 100%;
  display: inline-block;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $themeColor;
  }

  @include sphoneP {
    font-size: (18 / $baseFont) * 100%;
  }
}

.mod-title-02 {
  font-size: (16 / $baseFont) * 100%;
  color: $themeColor;
  padding-left: 30px;
  position: relative;
}

.mod-title-02-en {
  font-size: (70 / 16) * 100%;
  @extend .roboto;
  display: block;
  line-height: 1.1;
  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    background-color: $themeColor;
    position: absolute;
    left: 0;
    top: 40px;
  }

  @include sphoneP {
    font-size: (45 / 16) * 100%;
  }
}

.mod-title-03 {
  font-size: (20 / $baseFont) * 100%;
  color: $themeColor;
  position: relative;
  padding-left:30px;
  font-weight: bold;
  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    background-color: $themeColor;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @include sphoneP {
    font-size: (18 / $baseFont) * 100%;
  }
}

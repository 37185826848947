@charset "utf-8";

/*
#overview
other
*/

/*
#styleguide
xxxxx

```
コード
```
*/

/*↓消さないこと*/
.modLoading{
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background-color:rgba(255,255,255,0.8);

	i{
		@include fz(24);
		position:absolute;
		top:50%;
		left:50%;
		margin-left:-0.5em;
		margin-top:-0.5em;
	}
}
/*↑消さないこと*/

.bg-grey {
  background-color: #eee;
}

@charset "utf-8";

@mixin widhtWide {
  //PC幅広の場合
  @media only screen and (min-width: $baseWidthWide) {
	  @content;
  }
}

@mixin underWide {
  @media only screen and (max-width: $baseWidthWide) {
	  @content;
  }
}

@mixin tabletL {
  //タブレット横の場合
  @media only screen and (max-width: $bpTabletL) {
	  @content;
  }
}

@mixin tabletP {
  //タブレット縦の場合
  @media only screen and (max-width: $bpTabletP) {
	  @content;
  }
}

@mixin sphoneL {
  //スマートフォン横の場合
  @media only screen and (max-width: $bpSphoneL) {
	  @content;
  }
}

@mixin sphoneP {
  //スマートフォン縦の場合
  @media only screen and (max-width: $bpSphoneP) {
	  @content;
  }
}

// webfont icon
@mixin iconFont() {
	font-family: FontAwesome;
	line-height:1;
}

// clearfix
@mixin clearfix() {
 &:after {
 content:".";
 display:block;
 clear:both;
 height:0;
 visibility:hidden;
}
}

// font-size
@mixin fz($size: $baseFont,$important:false) {
 font-size: if($important, $size + px!important, $size + px);
 font-size: if($important, ($size / 10) + rem!important, ($size / 10) + rem);
}

// MV

.mv {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 12%;
  &::before {
    content: '';
    display: block;
    width: 46%;
    height: 80%;
    background-color: rgba(255, 255, 255, .9);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  @media only screen and (max-width: 1500px) {
    padding-bottom: 6%;
  }

  @include tabletL {
    padding-bottom: 6%;
    &::before {
      content: '';
      display: block;
      width: 55%;
    }
  }
  @include tabletP {
    &::before {
      content: none;
    }
  }
}

.mv__scroll {
  position: absolute;
  bottom: 10%;
  left: 20px;
  z-index: 3;

  @include tabletP {
    bottom: 5%;
  }

  @include sphoneP {
    left: 10px;
  }
}

.mv__inner {
  padding-top: 13%;

  @include tabletP {
    padding-top: 0;

    &.container {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
}

.mv__slider {
  width: 75%;

  @include tabletP {
    width: 100%;
  }

  &.is-show {
    &::after {
      width: 0;
    }
  }
}


.mv__slider-item {
  @include tabletP {
    position: relative;
  }
}

.mv_slider-name {
  display: block;
  text-align: right;
  margin-top: 10px;
  padding-right: 10px;
  br {
    display: none;
  }

  @include tabletL {
    br {
      display: block;
    }
  }

  @include tabletP {
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #fff;
    z-index: 2;
    font-weight: bold;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, .9);
  }
}

.mv__text {
  position: relative;
  z-index: 3;
  height: 100%;
  @include tabletL {
    padding-left: 60px;
  }
  @include tabletP {
    padding-left: 80px;
    padding-right: 80px;
  }

  @include sphoneP {
    padding-left: 45px;
    padding-right: 20px;
  }
}

.mv__text-title {
  font-size: (120 / $baseFont) * 100%;
  @extend .roboto;
  line-height: 1;
  color: $themeColor;
  text-transform: uppercase;
  letter-spacing: .03em;
  margin-bottom: 20px;
  display: block;
  @include underWide {
    font-size: (110 / $baseFont) * 100%;
  }

  @include tabletL {
    font-size: (80 / $baseFont) * 100%;
  }

  @include sphoneP {
    font-size: (65 / $baseFont) * 100%;
  }
}

.mv__text-subtitle {
  color: $themeColor;
  font-size: (15 / $baseFont) * 100%;
  display: none;
  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 1px;
    vertical-align: middle;
    margin-right: 10px;
    background-color: $themeColor;
    margin-bottom: 10px;
  }
  @include sphoneP {
    display: block;
  }
}

.mv__text-message {
  font-size: (16 / $baseFont) * 100%;
  @include tabletL {
    font-size: (14 / $baseFont) * 100%;
  }
}

.mv-slider-dots {
  margin-top: 40px;
}

// Solution and Consulting

[class*="sandc--"] {
  padding: 100px 0;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;

  @include sphoneL {
    padding: 60px 0;
  }

  @include sphoneP {
    padding: 40px 0;
  }
}

.sandc-title {
  margin-bottom: 20px;
  color: #fff;
  font-size: (30 / $baseFont) * 100%;
  position: relative;

  @include sphoneL {
    font-size: (24 / $baseFont) * 100%;
  }

  @include sphoneP {
    font-size: (20 / $baseFont) * 100%;
  }

}

.sandc-title-en {
  display: block;
  @extend .roboto;
  color: rgba(255, 255, 255, .25);
  font-size: (100 / 30) * 100%;
  letter-spacing: -0.03em;
  line-height: 1;
  br {
    display: none;
  }

  @include tabletL {
    font-size: (80 / 30) * 100%;
  }

  @include tabletP {
    br {
      display: block;
    }
  }
  @include sphoneL {
    font-size: (60 / 24) * 100%;
  }

  @include sphoneL {
    font-size: (48 / 20) * 100%;
  }
}

.sandc-message {
  color: #fff;
  font-size: (16 / $baseFont) * 100%;
}

.sandc--01 {
  background-image: url(../images/home/bg_solution01.jpg);
}

.sandc__diagram {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  @include sphoneP {
    display: block;
    margin-top: 40px;
  }
}

.sandc-diagram__item {
  width: 45%;
  flex-basis: 45%;
  &:last-child {
    img {
      width: 85%;
      height: auto;
    }
  }

  @include tabletP {
    width: 48%;
    &:last-child {
      img {
        width: 95%;
        height: auto;
      }
    }
  }

  @include sphoneP {
    width: 100%;
    &:first-child {
      margin-bottom: 30px;
    }
  }
}

.sandc-diagram-title {
  font-size: (20 / $baseFont) * 100%;
  color: #fff;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  display: inline-block;
}

.sandc--02 {
  background-image: url(../images/home/bg_solution02.jpg);
}

.sandc__timing {
  @extend .sandc__diagram;

  @include tabletP {
    display: block;
  }
}

.sandc-timing__item {
  width: 48%;
  flex-basis: 48%;
  color: #fff;

  @include tabletP {
    width: 100%;
    &:first-child {
      margin-bottom: 60px;
    }
  }
}

.sandc__timing-title {
  font-size: (20 / $baseFont) * 100%;
  text-align: center;
  margin-bottom: 20px;
  b {
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }
}

.sandc__timing-issue {
  display: table;
  border-collapse: separate;
  border-spacing: 8px 0;
  align-items: center;

  @include sphoneP {
    display: block;
  }
}

.sandc__timing-issue-item {
  display: table-cell;
  vertical-align: middle;
  width: 30%;
  border: 3px solid #fff;
  font-size: (16 / $baseFont) * 100%;
  text-align: center;
  padding: 40px 5px;
  max-height: 140px;

  @include sphoneL {
    font-size: (14 / $baseFont) * 100%;
  }

  @include sphoneP {
    display: block;
    width: 100%;
    padding: 12px 10px;
    margin-bottom: 20px;
    br {
      display: none;
    }
  }
}

.sandc__timing-arrow {
  text-align: center;
  margin: 10px 0;
}

.sandc__timing-solution {
  font-size: (24 / $baseFont) * 100%;
  text-align: center;

  @include sphoneL {
    font-size: (18 / $baseFont) * 100%;
  }
}


// Flow
.flow {
  padding: 120px 0;

  @include tabletP {
    padding: 80px 0;
  }

  @include sphoneL {
    padding: 60px 0;
  }

  @include sphoneP {
    padding: 40px 0;
  }
}

.flow-title {
  margin-bottom: 40px;
  br {
    display: none;
  }

  @include sphoneP {
    br {
      display: block;
    }
  }
}

.flow__01 {
  position: relative;
}

.flow__items {
  width: 72%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  @include underWide {
    width: 80%;
  }
  @include tabletL {
    padding-top: 0;
  }
  @include tabletP {
    width: 100%;
  }
}

.flow__item {
  width: 45%;
  @include underWide {
    width: 45%;
  }

  @include sphoneL {
    width: 48%;
  }
}

[class*="flow__item-chain--"] {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 110px;
  overflow: hidden;

  @include sphoneL {
    padding-top: 200px;
  }
}

.flow__item-chain--left {
  background: url(../images/home/img_flow-arrow-left_pc.png) no-repeat 80% 50%;

  @include sphoneL {
    background: url(../images/home/img_flow-arrow-left_sp.png) no-repeat 50% 50%;
  }
}

.flow__item-chain--right {
  background: url(../images/home/img_flow-arrow-right_pc.png) no-repeat 70% 50%;

  @include sphoneL {
    background: url(../images/home/img_flow-arrow-right_sp.png) no-repeat 50% 50%;
  }
}

.flow__company-name {
  width: 72%;
  background: $themeColor;
  margin: 0 auto 100px;
  padding: 25px 0;
  text-align: center;
  img {
    width: 300px;
    height: auto;
  }

  @include tabletP {
    width: 100%;
  }

  @include sphoneL {
    margin-bottom: 40px;

    img {
      width: 240px;
    }
  }
}

.flow-item__inner{
  background-color: #fff;
  padding: 40px 40px 30px;

  @include sphoneL {
    padding: 30px 20px 20px;
  }

  @include sphoneP {
    padding: 20px 10px 10px;
  }
}

.flow__item-title {
  font-size: (20 / $baseFont) * 100%;
  line-height: 1.4;
  margin-bottom: 10px;
  text-align: center;
  @include underWide {
    br {
      display: none;
    }
  }

  @include sphoneL {
    font-size: (16 / $baseFont) * 100%;
  }
}

.flow__item-title-en {
  @extend .roboto;
  font-size: (18 / 20) * 100%;
  color: $themeColor;
  display: block;
  margin-bottom: 5px;

}

.flow__item-list {
  padding: 0 20px;

  @include sphoneL {
    padding: 0;
  }
}

.flow__item-item {
  font-size: (16 / $baseFont) * 100%;
  position: relative;
  padding-left: 18px;
  text-align-last: left;
  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 2px;
    background-color: $themeColor;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  @include sphoneL {
    font-size: (14 / $baseFont) * 100%;
  }
}

.flow__01-title {
  font-size: (20 / $baseFont) * 100%;
  position: absolute;
  left: 0;
  top: 0;
  @include tabletL {
    position: static;
    margin-bottom: 10px;
  }

  @include sphoneL {
    font-size: (16 / $baseFont) * 100%;
  }
}

.flow-number {
  @extend .roboto;
  font-size: (36 / 20) * 100%;
  display: block;
  color: $themeColor;
  line-height: 1.2;
  margin-bottom: 8px;
  &::after {
    content: '';
    display: block;
    width: 1.1em;
    height: 1px;
    background-color: $themeColor;
  }
}

.flow__steps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include tabletP {
    flex-wrap: wrap;
    justify-content: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
  }

  @include sphoneL {
    width: 100%;
  }
}

.flow__steps-item {
  font-size: (18 / $baseFont) * 100%;
  line-height: 1.4;
  position: relative;
  flex-basis: 16%;
  .flow-number {
    margin-bottom: 10px;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 25px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 22px;
    height: 22px;
    border-top: 1px solid $themeColor;
    border-right: 1px solid $themeColor;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
  @include underWide {
    font-size: (16 / $baseFont) * 100%;
  }

  @include tabletL {
    &::after {
      right: 10px;
    }
  }

  @include tabletP {
    width: 33%;
    flex-basis: 33%;
    margin-bottom: 5px;
    &:nth-child(3) {
      &::after {
        content: none;
      }
    }
    &::after {
      right: 25px;
    }
  }

  @include sphoneL {
    font-size: (12 / $baseFont) * 100%;
    &::after {
      width: 14px;
      height: 14px;
      right: 15px;
    }
  }
}

// Case {
.case {
  padding: 100px 0 40px;

  @include tabletP {
    padding: 80px 0;
  }

  @include sphoneL {
    padding: 40px 0;
  }
}

.case-title {
  margin-bottom: 60px;
  @include sphoneL {
    margin-bottom: 40px;
  }
}

.case__pickup {
  margin-bottom: 90px;

  @include sphoneL  {
    margin-bottom: 40px;
  }
}

.case-pickup__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @include tabletP {
    display: block;
  }
}

.case-pickup__image,
.case-pickup__detail {
  width: 49%;

  @include tabletP {
    width: 100%;
  }
}

.case-pickup__image {
  img {
    width: 100%;
  }
}

.case-pickup-title {
  font-size: (18 / $baseFont) * 100%;
  margin-bottom: 30px;
}

.case-pickup-address {
  font-size: (14 / 18) * 100%;
  color: #666;
  display: block;
}

.case-pickup-point {
  background-color: #eee;
  display: inline-block;
  padding: 2px 15px;
}

.case-pickup-text {
  font-size: (16 / $baseFont) * 100%;
  margin: 5px 0 20px;
}

.case__items {
  margin: 0 0 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  &::before {
  	  content: "";
  	  display: block;
  	  width: 24%;
  	  order: 1;
  }  
  
  &::after {
  	  content: "";
  	  display: block;
  	  width: 24%;
  }  

  @include tabletP {
    margin-bottom: 40px;
  }

  @include sphoneL {
    margin-bottom: 20px;
  }

  @include sphoneP {
    display: block;
  }
}

.case__item {
  width: 24%;
  flex-basis: 24%;
  margin: 20px 0 0;
  img {
    width: 100%;
    height: auto;
  }

  @include tabletP {
    width: 48%;
    flex-basis: 48%;
    margin-bottom: 20px;
  }

  @include tabletP {
    width: 100%;
  }
}

.case__item-title {
  font-size: (16 / $baseFont) * 100%;
}

.case__item-address {
  font-size: (14 / 16) * 100%;
  color: #666;
  display: block;
}

// Company
.company {
  padding: 100px 0 160px;

  @include tabletP {
    padding: 0 0 50px;
  }

  @include sphoneL {
    padding-bottom: 30px;
  }
}

.company-title {
  margin-bottom: 60px;

  @include sphoneL {
    margin-bottom: 40px;
  }
}

.company__inner {
  display: flex;
  justify-content: space-between;

  @include tabletP {
    display: block;
  }
}

.company__box {
  width: 33%;
  padding: 0 0 30px 10px;
  border-right: 1px solid #ccc;
  &:last-child {
    border-right: none;
  }

  @include tabletP {
    width: 100%;
    border-right: none;
  }
}

.company__detail {
  margin-top: -30px;

  @include tabletP {
    margin-top: 0;
  }
}

.company__detail-name {
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 30px;
  font-size: (16 / $baseFont) * 100%;

  @include tabletP {
    margin-top: 20px;
  }
}

h3.company__detail-name {
  margin-top: 0;
}

.company__detail-value,
.company__partner {
  font-size: (16 / $baseFont) * 100%;
}

.company__partner-item {
  margin-bottom: 15px;
  a {
    display: block;
  }
}

// Contact
.contact {
  padding-bottom: 100px;
  .container {
    padding: 100px 200px;
  }
  @include tabletL {
    .container {
      padding: 100px;
    }
  }

  @include tabletP {
    .container {
      padding: 60px 40px;
    }
  }

  @include sphoneL {
    padding-bottom: 40px;
    .container {
      padding: 40px 20px;
    }
  }
}

.contact-title {
  color: $themeColor;
  font-size: (16 / $baseFont) * 100%;
  text-align: center;
  margin-bottom: 40px;
}

.contact-title-en {
  display: block;
  font-size: (68 / 16) * 100%;
  line-height: 1;

  @include sphoneP {
    font-size: (45 / 16) * 100%;
  }
}

.contact-message {
  text-align: center;
  font-size: (16 / $baseFont) * 100%;
}

.contact-memo {
  text-align: center;
  margin: 20px 0 60px;

  @include sphoneL {
    margin-bottom: 30px;
  }
}

// form

.form-confirm {
  margin-top: 100px;
  margin-bottom: -80px;

  @include tabletP {
    margin-top: 60px;
  }

  @include sphoneL {
    margin-bottom: -40px;
  }

  .form__submit-area {
    margin-top: 100px;

    @include tabletP  {
      margin-top: 60px;
    }
  }
}
.form-set {
  margin-bottom: 30px;
}

.form-set-label {
  display: block;
  font-size: (16 / $baseFont) * 100%;
  font-weight: bold;
  margin-bottom: 8px;
  .require {
    color: #EB5D5D;
    font-size: (12 / 16) * 100%;
    border: 1px solid #EB5D5D;
    padding: 3px 10px;
    margin-left: 10px;
  }

  .annotation {
    color: #666;
    font-size: (14 / 16) * 100%;
    font-weight: normal;
    margin-left: 15px;
  }
}

.form-set-confirm {
  display: block;
  font-size: (16 / $baseFont) * 100%;
  margin-bottom: 50px;

  @include sphoneL {
    margin-bottom: 30px;
  }
}

.form-set-text {
  display: block;
  width: 100%;
  border: none;
  &.has-error {
    border: 2px solid #EB5D5D;
  }
}

.form-message {
  height: 20em;

  @include sphoneL {
    height: 10em;
  }
}

.form__submit-area {
  .text-center {
    margin-bottom: 30px;
  }
}

@charset "utf-8";
/*
#overview
modList
*/

/*
#styleguide
modList　modIndent

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
<ul class="modList01">
    <li>Illustrator に原寸で配置したサイズでの解像度が350dpiになるようにしてください。</li>
    <li> 拡大縮小率70%〜141％を超えると画質が劣化するのでご注意ください。</li>
</ul>
```
*/

.modListNormal { margin: 0 0 0 2em; }

ul.modListNormal {
	li{
		&:before {
			content: "\0030fb";
			margin-left:-2em;
			display: inline-block;
			width:2em;
			text-align:center;
		}
	}
}

ol.modListNormal li { list-style:decimal; }

/* indent */
.modIndent01 li {
	text-indent: -1em;
	margin: 0 0 0 1em;
}

.modIndent02 li {
	text-indent: -2.5em;
	margin: 0 0 0 2.5em;
}

/* フォーム
***************************************/
.modDlForm{
	margin:0 0 20px;
	
	dt{
		float:left;
		clear:both;
		width:16em;
		padding:33px 0 20px 63px;
		position:relative;
		
		@include tabletP{
			float:none;
			width:auto;
			padding:33px 0 0 63px;
		}
		&.required:before,
		&.any:before{
			position:absolute;
			left:0;
			top:33px;
			color:#FFF;
			@include fz(11);
			padding:2px 10px;
		}
		&.required:before{
			
			content:"必須";
			background:$colorStrong;
			
		}
		&.any:before{
			background:#999999;
			content:"任意";
		}
	}
	dd{
		border-bottom:solid 1px #ccc;
		padding:10px 0;
		padding-left:17em;
		
		@include tabletP{
			padding:10px 0;
		}
		.parts{
			padding:10px 0;
			textarea,
			input[type="text"],
			input[type="email"]
			{
				width:100%;
				
			}
			&.radio_inline,
			&.check_inline{
				>div{
					display:inline-block;
				}
			}
			&.name{
				display:flex;
				justify-content:space-between;
				>div{
					width:48.5%;
				}
				
			}
			&.post,&.tel{
				display:flex;
				.hyphen{
					padding:10px 10px 0 10px;
					
					@include sphoneP{
						padding:10px 5px 0 5px;
					}
				}
			}
			&.password{
				input{
					width:100%;
					max-width:300px;
					@include sphoneP{
						max-width:none;
					}
				}
				.text{
					padding:10px 0;
				}
			}
		}
	}
	
	.validationError{
		color:$colorStrong;
	}
}
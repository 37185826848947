@charset "utf-8";
/* CSS Document */

.container {
	max-width:$container-md;

	@include widhtWide{
    	max-width: $container-lg;
	}

	@include tabletP{
    max-width: $container-sm;
  }
}

.modPcOnly {
	@include tabletP {
		display:none;
	}
}

.modSpOnly {
	display:none;
	@include tabletP {
		display: block;
	}
}

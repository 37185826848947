@charset "utf-8";

// color
$themeColor: #00226C;
$colorStrong: #E33E41; //強調系（エラーなど）
$colorBlue: #3498DB;
$colorGreen: #1ABC9C;

// font
$baseFont: 14;
$baseLineHeight: 1.8;
$baseColor: #333;
$baseLinkColor: $themeColor;
$mainColor: #333;

// brake-point
$bpSphoneP: 480px;
$bpSphoneL: 600px;
$bpTabletP: 768px;
$bpTabletL: 992px;
$baseWidthWide: 1200px;

// container-size
$container-sm: 750px;
$container-md: 970px;
$container-lg: 1160px;

// container-padding
$containerPadding: 15;

// font-family
$baseFontFamily :"游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$fontAlpha : Helvetica, Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
$fontMincho : "游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"メイリオ", Meiryo, serif;


// icon webfont
$fa-angle-right : "\f105";
$fa-angle-left : "\f104";
$fa-angle-down:"\f107";
$fa-angle-up:"\f106";
$fa-home : "\f015";
$fa-phone : "\f095";
$fa-file-pdf-o : "\f1c1";
$fa-bars : "\f0c9";
$fa-times : "\f00d";
$fa-search : "\f002";
$fa-caret-right: "\f0da";
$fa-caret-down: "\f0d7";
$fa-caret-left: "\f0d9";
$fa-caret-up: "\f0d8";
$fa-chevron-down: "\f078";
$fa-chevron-up: "\f077";
$fa-plus: "\f067";
$fa-minus: "\f068";
$fa-chevron-right : "\f054";
$fa-chevron-left : "\f053";
$fa-book : "\f02d";
$fa-square  : "\f0c8";
$fa-twitter : "\f099";
$fa-facebook :"\f09a";
$fa-envelope :"\f0e0";
$fa-bars : "\f0c9";
$fa-remove:"\f00d";
$fa-pencil : "\f040";
$fa-check :"\f00c";
$fa-circle-o : "\f10c";
$fa-dot-circle-o : "\f192";

